<template>
  <div class="task-add">
    <div class="main-Title bgff"><h2>标签过滤</h2></div>
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form ref="postData" :model="postData" :rules="rules" label-width="80px" size="small">
          <el-row :gutter="10" class="mb10">
            <el-col :span="7">
              <el-form-item label="Country" prop="country">
                <el-select
                  v-model="postData.country"
                  size="small"
                  placeholder="Please select"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in options.country"
                    :key="item.countryCode"
                    :label="item.countryCode"
                    :value="item.countryCode"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="OS" prop="os">
                <el-select
                  v-model="postData.os"
                  size="small"
                  placeholder="Please select"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in options.os"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="APP_ID" prop=""
                ><el-input v-model="postData.appId" placeholder="请输入APP_ID"
              /></el-form-item>
            </el-col>
            <el-col :span="3"
              ><el-button
                :loading="loading.btn"
                type="primary"
                size="small"
                style="width: 100%;"
                @click="addWhere(1)"
                >添加</el-button
              ></el-col
            >
          </el-row>
          <el-row :gutter="10" v-for="(item, index) in postData.whereList" :key="index">
            <el-col :span="23" :offset="1" class="mb10">
              <el-row :gutter="10">
                <el-col :span="3">
                  <el-select
                    v-model="item.logicalOperator"
                    size="small"
                    placeholder="Please select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in options.where"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-col>
                <el-col :span="1"
                  ><el-button
                    type="primary"
                    style="width: 100%;"
                    size="small"
                    @click="addWhere(2, index)"
                    >添加</el-button
                  ></el-col
                >
              </el-row>
            </el-col>
            <el-col :span="22" :offset="2">
              <el-row
                :gutter="10"
                v-for="(item2, index2) in item.whereChildList"
                :key="index2"
                style="margin-bottom: 20px;"
              >
                <el-col :span="5">
                  <el-select
                    v-if="index2 !== 0"
                    v-model="item2.logicalOperator"
                    size="small"
                    placeholder="Please select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="whereItem in options.where"
                      :key="whereItem.value"
                      :label="whereItem.label"
                      :value="whereItem.value"
                    />
                  </el-select>
                </el-col>
                <el-col :span="5" :offset="index2 == 0 ? 5 : 0">
                  <el-select
                    v-model="item2.tagName"
                    size="small"
                    placeholder="Please select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="tagNameItem in options.tagName"
                      :key="tagNameItem.value"
                      :label="tagNameItem.label"
                      :value="tagNameItem.value"
                    />
                  </el-select>
                </el-col>
                <el-col :span="5">
                  <el-select
                    v-model="item2.arithmeticOperator"
                    size="small"
                    placeholder="Please select"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="arithmeticOperatorItem in options.arithmeticOperator"
                      :key="arithmeticOperatorItem.value"
                      :label="arithmeticOperatorItem.label"
                      :value="arithmeticOperatorItem.value"
                    />
                  </el-select>
                </el-col>
                <el-col :span="5"
                  ><el-input v-model="item2.tagValue" size="small" placeholder=""
                /></el-col>
                <el-col :span="1"
                  ><el-button
                    type="danger"
                    size="small"
                    style="width: 100%;"
                    @click="deletes(index, index2)"
                    >删除</el-button
                  ></el-col
                >
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <el-form-item label="数据名称" prop="dataName"
                ><el-input v-model="postData.dataName" placeholder="请输入数据名称"
              /></el-form-item>
            </el-col>
            <el-col :span="4"
              ><el-button
                :loading="loading.btn"
                type="primary"
                size="small"
                @click="add('postData')"
                >上传数据</el-button
              ><el-button
                :loading="loading.getBtn"
                type="primary"
                size="small"
                @click="get('postData')"
                >查询数据</el-button
              ></el-col
            >
          </el-row>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
  import optionData from '@/assets/js/optionData';
  import { handleDataFilterAdd, handleDataFilterSearch } from '@/api/DDJ/data';
  import { fetchCountryList } from '@/api/DDJ/country';
  export default {
    data() {
      return {
        postData: {
          country: '',
          os: 'iOS',
          appId: '',
          dataName: '',
          whereList: [],
        },
        options: {
          country: [],
          os: optionData.osOption,
          where: [
            { label: 'AND', value: 'AND' },
            { label: 'OR', value: 'OR' },
            { label: 'NOT', value: 'NOT' },
          ],
          tagName: [
            { label: 'os_version', value: 'osVersion' },
            { label: 'brand', value: 'brand' },
            { label: 'recentActiveDays', value: 'recentActiveDays' },
            { label: 'language', value: 'language' },
            { label: 'source', value: 'source' },
            { label: 'appId', value: 'appId' },
          ],
          arithmeticOperator: [
            { label: '<', value: '<' },
            { label: '>', value: '>' },
            { label: '=', value: '=' },
          ],
        },
        loading: {
          btn: false,
          getBtn: false,
        },
        rules: {
          country: [
            {
              required: true,
              message: '请输入Country',
              trigger: 'blur',
            },
          ],
          os: [
            {
              required: true,
              message: '请输入OS',
              trigger: 'blur',
            },
          ],
          appId: [
            {
              required: true,
              message: '请输入APP_ID',
              trigger: 'blur',
            },
          ],
          dataName: [
            {
              required: true,
              message: '请输入数据名称',
              trigger: 'blur',
            },
          ],
        },
      };
    },
    mounted() {
      this.getCountry();
    },
    created() {},
    methods: {
      addWhere(type, index) {
        if (type == 1) {
          this.postData.whereList.push({
            logicalOperator: 'AND',
            whereChildList: [
              {
                tagName: '',
                arithmeticOperator: '',
                tagValue: '',
              },
            ],
          });
        }
        if (type == 2) {
          this.postData.whereList[index].whereChildList.push({
            logicalOperator: 'AND',
            tagName: '',
            arithmeticOperator: '',
            tagValue: '',
          });
        }
      },
      deletes(index, index2) {
        let lengs = this.postData.whereList[index].whereChildList.length - 1;
        if (lengs == 0) {
          this.postData.whereList.splice(index, 1);
        } else {
          this.postData.whereList[index].whereChildList.splice(index2, 1);
          delete this.postData.whereList[index].whereChildList[0].logicalOperator;
        }
      },
      // 国家
      getCountry() {
        fetchCountryList().then((res) => {
          this.options.country = res.result;
          this.postData.country = res.result[0].countryCode;
        });
      },
      // 保存
      add(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          handleDataFilterAdd(this.postData).then((res) => {
            console.log(res);
            this.$message({
              message: '数据上传成功',
              type: 'success',
            });
            this.$router.push({
              path: '/click/data/uploadList',
            });
          });
        });
      },
      get() {
        this.loading.getBtn = true;
        handleDataFilterSearch(this.postData).then((res) => {
          console.log(res);
          this.loading.getBtn = false;
          this.$message({
            message: res.result,
            type: 'success',
          });
        });
      },
    },
  };
</script>

<style></style>
